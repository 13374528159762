import BasePlugin from '../BasePlugin'
import qs from 'qs'

export default class ExportAddressProgramReport extends BasePlugin {
  async execute () {
    let model = this.context.getModel()

    let registry = Object.values(this.context.getParentContext().getDashboardComponents()).find(item => item[0].name === 'investApplication')

    if (!registry) {
      console.warn('Не найден реестр Заявок/Проектов')
      return false
    }

    let filters = registry[0].$refs.table.loadParams

    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/registryservice/plugins/execute/ExportAddressProgramReportAsyncCommand`,
      data: {
        approve_status_ids: model.approveStatus,
        unpack_status_ids: model.unpackStatus,
        start_date: model.dateStart,
        end_date: model.dateEnd,
        groups: model.groups,
        investment_request_filters: filters,
        async: 'true'
      }
    }).then((response) => {
      this.context.$msgbox.close()
      this.context.$msgbox({
        type: 'info',
        message: 'Выгрузка шаблона поставлена в очередь. По завершении получите уведомление'
      })
    })
  }
}
